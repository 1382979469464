[data-component='modal'] {
  .modal-wrapper.is-open {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    position: static;
    margin-left: 16.5px;
    margin-right: 16.5px;
    margin-top: auto;
    margin-bottom: auto;
    max-width: unset;

    @include mq('large') {
      margin-top: 140px;
    }

    @include mq('x-large') {
      max-width: 1100px; // as per figma
      margin-left: auto;
      margin-right: auto;
    }

    .close {
      max-width: var(
        --emu-component-container-max-width
      ); // to handle large screen width
      margin-left: auto;
      margin-right: auto;
      width: 100%; // to match figma
      left: 0;
      opacity: 0.5;
      font-size: 0px;
      line-height: 0px;

      span {
        height: var(--emu-common-spacing-large); // as per figma
        width: var(--emu-common-spacing-large); // as per figma
        background-color: var(--emu-common-colors-white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
        right: var(--emu-common-spacing-medium);

        @include mq('large') {
          height: 48px; // as per figma
          width: 48px; // as per figma
        }

        @include mq('x-large') {
          top: var(--emu-common-spacing-brand-medium);
          right: 57px;
        }

        &::before {
          content: url(../../assets/images/close-modal.png);
          display: block;
        }
      }
    }
  }

  &.interactive-map-modal {
    .modal-content {
      background-color: var(--emu-common-colors-transparent);
      max-width: 360px; // to match figma
      margin: auto;
      padding-top: 20px;
      padding-bottom: 20px;

      @include mq('large') {
        max-width: 427px; // to match figma
      }

      > .container:has(.brand-card),
      > .container.js-has-brand-card {
        display: flex;
        justify-content: center;
      }

      .close {
        position: relative;
        opacity: 1;

        span {
          background-color: var(--emu-common-colors-secondary-500);
          height: 36px;
          width: 36px;
          top: 18px;
          right: var(--emu-common-spacing-brand-xs);

          @include mq('large') {
            top: 20px;
          }
        }
      }
    }

    .brand-card {
      width: 100%; // as per figma

      .text {
        &:has(.brand-card__disc),
        &.has-brand-card__disc {
          & + .text > .brand-card__disc {
            margin-top: 21px;
          }
        }
      }

      &__content {
        min-height: 357px; // as per figma
        padding-top: 18px;
        padding-left: var(--emu-common-spacing-brand-xs);
        padding-right: var(--emu-common-spacing-brand-xs);
        padding-bottom: 13px;
        border: none;

        @include mq('large') {
          min-height: 360px; // as per figma
        }
      }

      &__title {
        margin-bottom: var(--emu-common-spacing-xs);

        @include mq('large') {
          margin-bottom: 10px;
        }
      }

      &__txt {
        margin-bottom: 9px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }

        p {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-medium);
          }
        }
      }
    }
  }

  &.product-list-banner {
    .modal-wrapper {
      height: auto;

      .modal-content {
        width: 100%;
        max-width: none;
        margin: var(--emu-common-spacing-none);
        max-height: 100vh;
        overflow: auto;

        ul {
          display: grid;
          padding-left: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('390px') {
            grid-template-columns: repeat(2, 1fr);
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
          }

          @include mq('medium') {
            grid-template-columns: repeat(4, 1fr);
          }

          li {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
            min-height: 58px; // As per design
            justify-content: center;
            align-items: center;
            display: flex;

            @include mq('390px') {
              border-right: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);

              // for the li elements in last row, remove border bottom
              &:nth-last-child(-n + 2):nth-child(2n + 1) {
                &,
                ~ li {
                  border-bottom: none;
                }
              }
            }

            @include mq('medium', 'max-width') {
              &:nth-child(2n) {
                border-right: none;
              }
            }

            @include mq('medium') {
              &:nth-child(4n) {
                border-right: none;
              }

              // for the li elements in last row, remove border bottom
              &:nth-last-child(-n + 4):nth-child(4n + 1) {
                &,
                ~ li {
                  border-bottom: none;
                }
              }
            }

            a {
              font-size: var(--emu-common-font-sizes-captions-medium);
              line-height: 14px;
              text-decoration: none;
              border-bottom-width: var(--emu-common-border-width-none);
              letter-spacing: 0.5px;
              padding-top: var(--emu-common-spacing-medium);
              padding-bottom: 13px;

              &:hover {
                text-decoration: underline;
              }
            }

            sup {
              font-size: 75%;
            }

            + li {
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }

        .close {
          opacity: 1;
          left: auto;
          right: 0;
          width: auto;

          span {
            position: static;
            background-color: var(--emu-common-colors-transparent);
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.js-modal-opened {
  overflow: hidden;

  @include aem-editor-view {
    overflow: initial;
  }
}
